import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Visa = () => {
  const headingbanner = {
    title: `Visa`,
    content: `A Campaign Monitoring Platform`,
  };

  const data = {
    images: ["visa-work.webp", "visa-work-2.webp"],
    firstAlt: "UI UX Screen To Monitor Campaigns",
    firstTitle: "Projects Overview",
    secondAlt: "UI Dashboard To Monitor Each Campaign Performance",
    secondTitle: "Dashboard",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Enterprise"],
      },
      {
        title: "What we did",
        text: ["User Research", "UX Consultation", "UI UX Designing"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `When we were approached by Visa’s team, they lacked a digital platform to monitor the performance of their campaigns.`,
          `Earlier they used Excel to maintain their data and manually analyse and make decisions regarding their performance, campaign investments etc. It was a time-consuming task and increased the chances of human error. `,
          `They struggled to access the effectiveness of their marketing efforts which also leads to investing time and resources into underperforming campaigns.`,
          `Without a campaign tracking platform, they used to miss out on the market trends and sometimes failed to tap the opportunities. `,
        ],
      },
      {
        title: "Solution",
        content: [
          `We created a digital campaign monitoring platform to gather and analyse the performance of the campaign effectively and efficiently.`,
          `We understood the client’s needs and provided them with a comprehensive platform that can analyse and optimise the campaign performance without any human assistance.`,
          `The first step of our solution started with conducting user research, understanding their preferences and analysing the insights gained.`,
          `Post that, we created a plan and involved our client’s team for shared insights and feedback to make decisions that align with their business goals.`,
          `We created a platform through which users can now look into the performance of their campaign effortlessly, make informed decisions from the data analysed automatically and optimise and customise the platform based on their specific business requirements.`,
        ],
      },
    ],
    image: ["visa-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Analysing Dashboards",
    para: [
      `The dashboard gives an overview of the project to the users to gain a better understanding of their marketing efforts. They can also customise the data by filtering it based on the campaign's needs and gaining useful insights.`,
    ],
    content: {
      imageAlt: 'UI Dashboard to check campaign performance alongwith filter option',
      imageTitle: 'Dashboard',
      image: ["visa-projectone.webp"],
      text: `The user of the platform can also set and analyse the monthly, quarterly and annual budgets to make investment-based decisions. `,
    },
    content2: {
      imageAlt: 'Dashboard for analysing overall VIK summary',
      imageTitle: 'Project Performance',
      image: ["visa-projectone-2.webp"],
      text: `This platform serves all the purposes of the campaign right from gathering and analysing data to assisting in making monetary decisions. `,
    },
  };

  const projectData2 = {
    title: "OPEX Data",
    para: [
      `We also created a dashboard interface that can simplify the data related to operational expenditure in a periodical manner.`,
    ],
    content: {
      image: ["visa-projecttwo.webp"],
      imageAlt: 'Insights on Opex data alognwith filter option',
      imageTitle: 'Opex Data',
    },
  };

  const projectData3 = {
    title: "Calendar",
    para: [
      `We also created a calendar in the platform that can track the status, monthly spending, periodical performance etc. of campaigns from different industries/ categories.`,
    ],
    content: {
      image: [
        "visa-projectthree.webp",
        "visa-projectthree-2.webp",
        "visa-projectthree-3.webp",
        "visa-projectthree-4.webp",
        "visa-projectthree-5.webp",
      ],
      firstAlt: 'Yearly Campaign Status',
      firstTitle: 'Calendar',
      secondAlt: 'BCM-ILM Details based on certain categories',
      secondTitle: 'BCM-ILM Details',
      thirdAlt: 'Detailed Information about the campaign',
      thirdTitle: 'Project Detail',
      fourthAlt: 'BCM-ILM Details based on certain categories',
      fourthTitle: 'BCM-ILM Details',
      fifthAlt: 'UI Dashboard to check brand health',
      fifthTitle: 'Brand Health',
    },
  };

    const conclusionData = [
      {
        para: [
          `We created this platform for Visa, particularly for their campaign monitoring in the co-marketing space. Their main aim is to digitally gather, analyse and gain insights into various campaigns run in collaboration with different businesses. `,
          `They earlier used to run and analyse the insights of the campaign in an Excel spreadsheet which was not an effective and efficient method and also increased the chances of human error.`,
          `We transformed the manual process of their campaign into the digital landscape which resulted in maximised performance, less error, increased ROI and a competitive edge in the market.`,
        ],
      },
    ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={6} />
      </div>
    </Layout>
  );
};

export default Visa;

export const Head = () => (
  <>
    <meta name="keywords" content="visa, ui ux design, case study, performance, monitor, tracking, ui ux case study, project" />
    <Seo title="Visa- Campaign Monitoring Platform Case Study | Octet" description="Octet Design Studio conducted user research, offered UX consultation and UI UX designing services for brand monitoring platform, Visa." />
  </>
)